.pagination {
  .page-link {
    font-size: $font-size-pagination-md;
    font-weight: $font-weight-bold;

    display: flex;

    width: 48px;
    height: 48px;
    margin: 0 4px !important;

    border-radius: 50%;

    align-items: center;
    justify-content: center;
  }

  &-sm {
    .page-link {
      font-size: $font-size-pagination-sm;

      width: 40px;
      height: 40px;
      margin: 0 3px !important;
    }
  }

  &-lg {
    .page-link {
      font-size: $font-size-pagination-lg;

      width: 56px;
      height: 56px;
      margin: 0 5px !important;
    }
  }
}
