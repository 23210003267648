.gallery {
  &.wall {
    .card {
      background-color: $brand-tertiary;
      box-shadow: none;

      .headline {
        font-family: 'CenturyGothic', sans-serif;
      }
    }
  }
}
