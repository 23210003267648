
$brand-primary: #df1f38;
$brand-secondary: #848687;
$brand-tertiary: #f5f5f5;

$brand-white: #fff;
$brand-black: #000;

$brand-light: $brand-white;
$brand-dark: $brand-black;

$brand-success: #202922;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
