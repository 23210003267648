.gallery {
  &.cards-slider {
    .slick-list {
      margin-top: rem(-16px);    // works in conjunction with box-shadow fix below
      margin-bottom: rem(-16px); // works in conjunction with box-shadow fix below
    }

    .slick-track {
      display: flex !important;
      overflow: hidden;

      padding: rem(16px) 0;      // box-shadow fix

      align-items: stretch !important;
      justify-content: stretch !important;
    }

    .card {
      background-color: $brand-tertiary;
      box-shadow: none;

      .headline {
        font-family: 'CenturyGothic', sans-serif;
      }
    }
  }
}
