.nav {
  &-link {
    position: relative;

    &.active {
      color: shade-color($brand-dark, 40%);
    }

    &:hover,
    &:focus,
    &:active {
      &::before {
        position: absolute;
        right: 12px;
        bottom: 0;
        left: 12px;

        display: block;

        height: 2px;

        content: '';

        background-color: $primary;
      }
    }
  }
}
