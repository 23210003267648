#pre-footer {
  .google-maps {
    margin-right: $container-padding-x;
    margin-left: $container-padding-x;
  }
}

.footer {
  .additional-image {
    img {
      display: block;

      margin: 0 auto;
    }
  }

  &_info {
    .fact-sheet {
      &-item {
        .headline {
          text-align: center;
        }
      }
    }
  }
}
