.btn {
  &-light {
    color: $primary;

    &:hover,
    &:focus,
    &:active {
      color: shade-color($primary, 20%);
      background-color: shade-color($light, 5%);
    }
  }

  &-dark {
    &:hover,
    &:focus,
    &:active {
      background-color: tint-color($dark, 20%);
    }
  }

  &-warning {
    &:hover,
    &:focus,
    &:active {
      background-color: shade-color($warning, 15%);
    }
  }

  &:not([class*='btn-outline']) {
    border: none;
  }

  @each $color, $value in $theme-colors {
    &-outline-#{$color} {
      &:hover,
      &:focus,
      &:active {
        color: $value;
        background-color: transparentize($value, 0.92);
      }
    }
  }

  &-outline {
    &-primary {
      &:hover,
      &:focus,
      &:active {
        color: shade-color($primary, 20%);
        border-color: shade-color($primary, 25%);
        background-color: transparent;
      }
    }

    &-secondary {
      &:hover,
      &:focus,
      &:active {
        color: tint-color($secondary, 20%);
        border-color: tint-color($secondary, 15%);
        background-color: transparent;
      }
    }

    &-light {
      &:hover,
      &:focus,
      &:active {
        color: shade-color($light, 10%);
        border-color: shade-color($light, 5%);
        background-color: transparent;
      }
    }

    &-dark {
      &:hover,
      &:focus,
      &:active {
        color: tint-color($dark, 20%);
        border-color: tint-color($dark, 15%);
        background-color: transparent;
      }
    }
  }
}
