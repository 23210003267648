// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height !default;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0);

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius-sm !default;
$btn-border-radius-lg: $border-radius-lg !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$btn-hover-bg-shade-amount: 20%;
$btn-hover-bg-tint-amount: 20%;
$btn-hover-border-shade-amount: 25%;
$btn-hover-border-tint-amount: 15%;
$btn-active-bg-shade-amount: 25%;
$btn-active-bg-tint-amount: 25%;
$btn-active-border-shade-amount: 30%;
$btn-active-border-tint-amount: 15%;
