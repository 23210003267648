h1 {
  line-height: $line-height-heading-desktop-1;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-1;
    line-height: $line-height-heading-mobile-1;
  }
}

h2 {
  line-height: $line-height-heading-desktop-2;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-2;
    line-height: $line-height-heading-mobile-2;
  }
}

h3 {
  line-height: $line-height-heading-desktop-3;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-3;
    line-height: $line-height-heading-mobile-3;
  }
}

h4 {
  line-height: $line-height-heading-desktop-4;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-4;
    line-height: $line-height-heading-mobile-4;
  }
}

h5 {
  line-height: $line-height-heading-desktop-5;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-5;
    line-height: $line-height-heading-mobile-5;
  }
}

h6 {
  line-height: $line-height-heading-desktop-6;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-heading-mobile-6;
    line-height: $line-height-heading-mobile-6;
  }
}
