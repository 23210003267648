.form {
  &-floating {
    > label {
      font-size: 14px;
      line-height: 1.75;

      color: $secondary;
    }
  }

  &-check {
    &-inline {
      margin-right: rem(30px);
    }
  }
}
