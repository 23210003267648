// Links
//
// Style anchor elements.

$link-color: $black;
$link-decoration: none;
$link-shade-percentage: 25%;
$link-hover-color: shade-color($secondary, 25%);
$link-hover-decoration: underline;

$stretched-link-pseudo-element: after !default;
$stretched-link-z-index: 1 !default;
