.menu-section {
  &.on-page {
    display: flex;
    overflow: auto;

    .nav {
      display: flex;

      margin: 0 auto;

      flex-wrap: nowrap;

      &-item {
        flex-shrink: 0;
      }

      &-link {
        margin-right: rem(12px);  // set up margins to fix underlines
        margin-left: rem(12px);   // set up margins to fix underlines
        padding-right: rem(12px);
        padding-left: rem(12px);

        color: $secondary;
      }
    }
  }
}
