.slick-slide {
  height: auto !important;
  padding: 0 20px;

  flex-shrink: 0;

  > div {
    display: flex;

    height: 100%;
  }
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.slick-prev {
  background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'1 -3 11 16\' width=\'32\' height=\'32\' fill=\'#{$primary}\'><path transform=\'rotate(180 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));

  &:hover,
  &:focus,
  &:active {
    background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'1 -3 11 16\' width=\'32\' height=\'32\' fill=\'#{$primary}\'><path transform=\'rotate(180 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));
  }
}

.slick-next {
  background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'3 -3 11 16\'  width=\'32\' height=\'32\'  fill=\'#{$primary}\'><path transform=\'rotate(0 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));

  &:hover,
  &:focus,
  &:active {
    background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'3 -3 11 16\'  width=\'32\' height=\'32\'  fill=\'#{$primary}\'><path transform=\'rotate(0 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));
  }
}

.bg_color_second {
  .slick-prev {
    background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'1 -3 11 16\' width=\'32\' height=\'32\' fill=\'#{$carousel-control-color}\'><path transform=\'rotate(180 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));

    &:hover,
    &:focus,
    &:active {
      background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'1 -3 11 16\' width=\'32\' height=\'32\' fill=\'#{$carousel-control-color}\'><path transform=\'rotate(180 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));
    }
  }

  .slick-next {
    background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'3 -3 11 16\'  width=\'32\' height=\'32\'  fill=\'#{$carousel-control-color}\'><path transform=\'rotate(0 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));

    &:hover,
    &:focus,
    &:active {
      background: escape-svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'3 -3 11 16\'  width=\'32\' height=\'32\'  fill=\'#{$carousel-control-color}\'><path transform=\'rotate(0 7 4.5)\' d=\'M4.68406,-2.27915v0l-1.57259,1.57259v0l5.09566,5.09566v0l-5.09566,5.09566v0l1.57259,1.57259v0l6.66825,-6.66825v0z\'/></svg>'));
    }
  }
}
