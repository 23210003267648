.language-select {
  &.DE {
    &:before {
      content: "";
      display: block;
      background: url("../../../../Images/svg/germany.svg") no-repeat;
      width: 20px;
      height: 20px;
      float: left;
      margin: 5px 6px 0 0;
    }
  }

  &.EN {
    &:before {
      content: "";
      display: block;
      background: url("../../../../Images/svg/united-kingdom.svg") no-repeat;
      width: 20px;
      height: 20px;
      float: left;
      margin: 5px 6px 0 0;
    }
  }
}
