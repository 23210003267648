.bg_color_second {
  hr:not(.color-static),
  .preline:not(.color-static),
  .headline:not(.color-static),
  .teaser:not(.color-static),
  .link:not(.color-static),
  [class|='form']:not(.color-static):not(.form-floating):not(.is-invalid) {
    color: $white !important;
  }

  table {
    th,
    tr {
      color: $white !important;
      border-color: $white !important;
    }
  }

  legend {
    color: $white !important;
  }

  .accordion {
    .accordion,
    .accordion-flush {
      &-button,
      &-body {
        color: $white !important;
      }

      &-button {
        &::after {
          background-image: escape_svg(url('data:image/svg+xml,<svg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\' fill=\'#{$white}\'><path fill-rule=\'evenodd\' d=\'M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z\'/></svg>'));
        }
      }
    }
  }

  .investment-calculator {
    &-type,
    &-label {
      color: $white !important;
    }

    &-range {
      &::-webkit-slider-thumb {
        background-color: $primary;
      }
    }

    &-range {
      &::-moz-range-thumb {
        background-color: $primary;
      }
    }

    &-range {
      &::-ms-thumb {
        background-color: $primary;
      }
    }

    &-field {
      span {
        color: $white;
      }
    }

    .chart {
      [role='region'] {
        > g {
          fill-opacity: 1;
        }
      }
    }
  }

  .performance-chart {
    .amcharts-range-selector-period-button {
      color: $white;
    }

    .chart {
      [role='region'] {
        > g {
          fill-opacity: 1;
        }
      }
    }
  }

  .investment-quiz {
    &-step {
      &-heading {
        color: $white;
      }
    }
  }

  .fact-sheet {
    a {
      color: $white;
    }
  }

  .text-and-bullets {
    &.icons {
      .list {
        li {
          color: $white;

          svg {
            color: $white;
          }
        }
      }
    }
  }
}

.centered {
  > .container {
    > .row {
      [class|='col'] {
        .preline {
          text-align: center;

          &::after {
            left: 50%;

            transform: translateX(-50%);
          }
        }

        .headline {
          text-align: center;
        }

        .teaser {
          text-align: center;
        }

        .links {
          justify-content: center;
          @include media-breakpoint-down(md) {
            align-items: center;
          }
        }
      }
    }
  }
}
